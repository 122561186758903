import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";
import { mobile, mobileSmall } from "clutch/src/Style/style.mjs";

import TftColors from "@/game-tft/colors.mjs";
import useMatchListStats from "@/game-tft/use-matchlist-stats.mjs";

const HeaderFrameStyles = styled("div")`
  .header-frame {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    color: var(--shade1);
  }
`;

const OverallStats = styled("div")`
  display: flex;
  justify-content: space-between;
`;
const HeaderStat = styled("div")`
  margin-right: var(--sp-7);

  &:last-of-type {
    margin-right: 0;
  }
`;
const StatTop = styled("div")`
  color: var(--shade1);
`;
const Wins = styled("span")`
  font-weight: 700;
  color: var(--shade3);
  ${({ wins }) => wins > 0 && `color: ${TftColors.placement.solid[1]};`};
`;
const StatBottom = styled("div")`
  font-size: var(--sp-3);
`;
// Summary box
const MatchHistorySummary = styled("div")`
  box-sizing: border-box;
  padding: var(--sp-1);
  display: flex;
  width: 390px;
  background: var(--shade5-25);
  border-radius: var(--br-sm);

  ${mobile} {
    width: 105px;
  }

  ${mobileSmall} {
    width: var(--sp-20);
  }
`;
const AverageRank = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  span {
    display: block;
    line-height: 1.3;
  }
`;
const AvgMissing = styled("div")`
  color: var(--shade3);
`;
const Avg = styled(AvgMissing)`
  ${({ $avg }) =>
    $avg < 2
      ? `color: ${TftColors.placement.solid[1]};`
      : $avg < 3
        ? `color: ${TftColors.placement.solid[2]};`
        : $avg < 4
          ? `color: ${TftColors.placement.solid[3]};`
          : $avg < 5
            ? `color: var(--shade1);`
            : `color: var(--shade1);`};
`;
const MatchSquares = styled("div")`
  display: flex;
  flex-wrap: wrap;
  width: 300px;

  ${mobile} {
    display: none;
  }
`;
const MatchesMissingSquare = styled("div")`
  display: grid;
  place-content: center;
  height: var(--sp-6);
  width: var(--sp-6);
  margin: 3px;
  color: var(--white);
  background: var(--shade7);
  border-radius: var(--br-sm);
`;

const MatchSquare = styled(MatchesMissingSquare)`
  ${({ $rank }) =>
    $rank < 5
      ? `background: ${TftColors.placement.solid[$rank]} !important;`
      : `color: var(--shade3) !important;
        background: var(--shade8) !important;`};
`;

const ProfileOverviewMatchHistoryHeader = ({ account }) => {
  const { t } = useTranslation();
  const fullName = `${account?.account?.game_name}-${account?.account?.tag_line}`;

  const { placements } = useMatchListStats(fullName);
  const wins = placements.filter((v) => v === 1).length;
  const top4 = placements.filter((v) => v <= 4).length;
  const count = placements.filter(Boolean).length;
  const placementAvg = placements.reduce((a, v) => a + v, 0) / (count || 1);

  return (
    <HeaderFrameStyles>
      <Card className="header-frame">
        <div>
          <OverallStats>
            <HeaderStat>
              <StatTop className="type-caption--bold">
                <Wins wins={wins}>
                  {t("tft:countWin", "{{count}} Wins", { count: wins })}
                </Wins>
              </StatTop>
              <StatBottom>
                {t("tft:lastCountGames", "Last {{count}}", {
                  count: placements.length,
                })}
              </StatBottom>
            </HeaderStat>
            <HeaderStat>
              <StatTop className="type-caption--bold">{top4}</StatTop>
              <StatBottom>{t("tft:topFour", "Top 4")}</StatBottom>
            </HeaderStat>
          </OverallStats>
        </div>
        <MatchHistorySummary>
          <AverageRank>
            <Avg $avg={placementAvg} className="type-body1-form--active">
              {t("common:poundNum", "#{{num}}", {
                num: placementAvg.toFixed(1),
              })}
            </Avg>
            <div className="type-caption">
              {t("common:avgPlace", "Avg. Place")}
            </div>
          </AverageRank>
          <MatchSquares>
            {placements.map((v, i) => {
              if (typeof v !== "number") {
                return <MatchesMissingSquare key={i} />;
              }
              return (
                <MatchSquare key={i} $rank={v}>
                  <div className="type-caption--bold">{v}</div>
                </MatchSquare>
              );
            })}
          </MatchSquares>
        </MatchHistorySummary>
      </Card>
    </HeaderFrameStyles>
  );
};

export default ProfileOverviewMatchHistoryHeader;
