import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import MatchTile from "@/game-tft/MatchTile.jsx";
import {
  MatchList as SharedMatchList,
  MatchTile as SharedMatchTile,
} from "@/shared/Profile.jsx";
import { ProfileMatchlist } from "@/shared/Profile.style.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const Matchlist = ({ account }) => {
  const {
    parameters: [platform],
  } = useRoute();
  const state = useSnapshot(readState);
  const fullName = `${account?.account?.game_name}-${account?.account?.tag_line}`;
  const matchList = state.tft.matchLists_v2[fullName];

  return (
    <ProfileMatchlist>
      <SharedMatchList matchList={matchList}>
        {(Array.isArray(matchList) ? matchList : []).map((matchId) => {
          const match = state.tft.matches_v2[matchId];
          const [_platform, scrubbedMatchId] = matchId.split("_");

          const matchRoute = `/tft/match/${platform}/${fullName}/${scrubbedMatchId}`;

          return (
            <React.Fragment key={matchId}>
              <SharedMatchTile
                id={matchId}
                height={138}
                match={match}
                matchRoute={matchRoute}
                handleMouseEvents={true}
              >
                <MatchTile
                  name={fullName}
                  match={match}
                  matchId={matchId}
                  mathchRoute={matchRoute}
                />
              </SharedMatchTile>
            </React.Fragment>
          );
        })}
      </SharedMatchList>
    </ProfileMatchlist>
  );
};

export default Matchlist;
