// This is used to represent a single unit
// when presented from a match (mathtile, postmatch, etc.)
import React, { forwardRef, memo } from "react";
import { styled } from "goober";

import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { Stars } from "@/game-tft/CommonComponents.jsx";
import { ITEM_BLACKLIST } from "@/game-tft/constants.mjs";
import getItemByItemSet from "@/game-tft/get-item-by-item-set.mjs";
import StaticTFT from "@/game-tft/static.mjs";
import ChosenPurpleIcon from "@/inline-assets/chosen-purple.svg";
import useDeferredTooltip from "@/util/use-deferred-tooltip.mjs";

const Champion = styled("div", forwardRef)`
  position: relative;
  margin-right: var(--sp-1_5);
  margin-top: var(--sp-3);
  width: ${({ width }) => width};

  &:last-of-type {
    margin-right: 0;
  }

  .chosenIcon {
    position: absolute;
    z-index: 2;
    right: calc(-1 * var(--sp-0_5));
    top: -5px;
    width: 0.825rem;
  }
`;
const ChampionLevelApi = styled("div")`
  position: absolute;
  top: calc(var(--sp-4) * -1);
  left: 50%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  z-index: 2;
`;
const PieceItems = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: var(--sp-0_5);

  img {
    height: auto;
    align-self: center;
    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    width: var(--sp-3) !important;
    border: var(--sp-px) solid var(--shade5);
  }
`;

const MatchTileApiUnitListMatch = ({
  unitSize = 32,
  champion,
  tooltip,
  tier,
  set,
  itemsBySet,
  itemKeys = [],
  isSkinsEnabled,
  chosen,
}) => {
  const ref = useDeferredTooltip(tooltip);
  return (
    <Champion width={unitSize} ref={ref} data-place="top">
      <ChampionLevelApi>
        <Stars tier={tier} />
      </ChampionLevelApi>
      {Boolean(chosen) && <ChosenPurpleIcon className="chosenIcon" />}
      <ChampionImage
        colorlessBorder
        champKey={champion.apiKey}
        cost={champion?.cost}
        size={unitSize}
        set={set}
        skinSetting={isSkinsEnabled}
        chosen={chosen}
      />
      {Boolean(itemsBySet) && (
        <PieceItems>
          {itemKeys.map((itemKey, i) => {
            if (ITEM_BLACKLIST.includes(itemKey)) return null;
            const unitItem = getItemByItemSet(
              { key: "apiKey", value: itemKey },
              itemsBySet,
            );
            if (unitItem) {
              return (
                <img
                  src={StaticTFT.getItemImage(itemKey, set)}
                  key={`${itemKey}_${i}`}
                  alt={unitItem.name}
                />
              );
            }
            return <img src="" alt="fallback" key={`${itemKey}_${i}`} />;
          })}
        </PieceItems>
      )}
    </Champion>
  );
};

export default memo(MatchTileApiUnitListMatch);
