import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import Card from "clutch/src/Card/Card.jsx";
import { t } from "i18next";

import { RANK_SYMBOL_TO_STR } from "@/game-lol/constants/constants.mjs";
import {
  convertRankFromRomanToNumber,
  getRankColor,
} from "@/game-lol/utils/rank-utils.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";
import {
  QUEUE_SYMBOLS,
  QUEUE_SYMBOLS_TO_QUEUE_NAMES,
  topRanks,
} from "@/game-tft/constants.mjs";
import ProfileMatchlist from "@/game-tft/ProfileMatchlist.jsx";
import ProfileOverviewHyperRollRankCard from "@/game-tft/ProfileOverviewHyperRollRankCard.jsx";
import ProfileOverviewMatchHistoryHeader from "@/game-tft/ProfileOverviewMatchHistoryHeader.jsx";
import ProfileOverviewPlacementDistribution from "@/game-tft/ProfileOverviewPlacementDistribution.jsx";
import ProfileOverviewStatsBlock from "@/game-tft/ProfileOverviewStatsBlock.jsx";
import Static from "@/game-tft/static.mjs";
import useMatchListStats from "@/game-tft/use-matchlist-stats.mjs";
import { getHyperRollRanksByRating } from "@/game-tft/utils.mjs";
import Container from "@/shared/ContentContainer.jsx";
import SharedProfile from "@/shared/Profile.jsx";
import { ProfileColumn } from "@/shared/Profile.style.jsx";
import ProfileRank from "@/shared/ProfileRank.jsx";
import { formatToPercent, getLocale } from "@/util/i18n-helper.mjs";

const Stats = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--sp-4);
  color: var(--shade2);
  padding-block: var(--sp-4);

  span {
    margin-left: var(--sp-1);
    color: var(--shade0);
  }
`;

const TFTStats = ({ account }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const fullName = `${account?.account?.game_name}-${account?.account?.tag_line}`;
  const { placements, avgPlacement, count } = useMatchListStats(fullName);

  const avgPlace = avgPlacement.toLocaleString(getLocale(), {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  });
  const top4Rate = placements.filter((p) => p <= 4).length / count;

  return (
    <Stats className="type-caption">
      <div>
        {t("common:avgPlace", "Avg. Place")}: <span>{avgPlace}</span>
      </div>
      <div>
        {t("common:topFour", "Top Four")}:{" "}
        <span>{formatToPercent(language, top4Rate)}</span>
      </div>
    </Stats>
  );
};

export function ProfileOverview({ account }) {
  const hyperRollRank = (account?.league_tft ?? []).find(
    ({ queue_type }) =>
      queue_type === QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTftTurbo],
  );

  return (
    <Container>
      <SharedProfile>
        <ProfileColumn className="sidebar">
          <ProfileRanks account={account} />
          {hyperRollRank ? (
            <ProfileOverviewHyperRollRankCard leagueInfo={hyperRollRank} />
          ) : null}
          <ProfileOverviewStatsBlock statsType="traits" account={account} />
          <ProfileOverviewStatsBlock statsType="units" account={account} />
        </ProfileColumn>
        <ProfileColumn className="main">
          <ProfileOverviewMatchHistoryHeader account={account} />
          <ProfileMatchlist account={account} />
        </ProfileColumn>
      </SharedProfile>
    </Container>
  );
}

const PROFILE_QUEUES = [
  {
    key: QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTft],
    name: ["tft:queues.ranked", "Ranked"],
  },
];

const SECONDARY_QUEUES = [
  {
    key: QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTftTurbo],
    name: ["tft:queues.hyperRoll", "Hyper Roll"],
  },
  {
    key: QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTftDoubleUp],
    name: ["tft:queues.doubleup", "Double Up"],
  },
];

function ProfileRanks({ account }) {
  const { t } = useTranslation();

  if (!account) {
    return <Card style={{ height: 388 }} />;
  }

  let { league_tft: leagues } = account;
  if (!Array.isArray(leagues)) leagues = [];

  const secondaryQueues = SECONDARY_QUEUES.map((queue) => {
    const rankInfo = leagues.find((rank) => rank.queue_type === queue.key);
    const {
      wins = 0,
      losses = 0,
      league_points: leaguePoints = 0,
      tier,
      rank,
      // Hyper Roll
      rated_rating: ratedRating = 0,
    } = rankInfo || {};
    const division = !topRanks.includes(tier) ? rank : null;

    const queueName = queue.name;
    const tierSymbol = RANK_SYMBOL_TO_STR[RankSymbol(tier)];
    const tierName = tierSymbol
      ? [tierSymbol.t.name, tierSymbol.t.fallback]
      : ["common:rank.unranked", "Unranked"];
    const tierString = t([...tierName]);
    const nonRomanDivision = convertRankFromRomanToNumber(division);

    const isHyperRoll =
      queue.key === QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTftTurbo];
    const hyperRollRank = isHyperRoll
      ? getHyperRollRanksByRating(ratedRating)
      : null;
    const rankColor = !isHyperRoll
      ? tierSymbol
        ? getRankColor(tierSymbol.key)
        : "var(--shade2)"
      : hyperRollRank.color;

    const games = wins + losses;
    const winrate = wins / (games || 1);
    const winrateColor = !games
      ? "var(--shade2)"
      : winrate >= 0.5
        ? "var(--perf-pos3)"
        : "var(--perf-neg2)";

    return {
      queueIcon: null,
      label: t([...queueName]),
      rankName: !isHyperRoll
        ? (tierString &&
            nonRomanDivision &&
            `${tierString} ${nonRomanDivision}`) ||
          tierString
        : ratedRating
          ? `${t(...hyperRollRank.t)} (${ratedRating})`
          : `${t(...hyperRollRank.t)}`,
      points: !isHyperRoll
        ? t("lol:leaguePoints", "{{points}} LP", {
            points: leaguePoints,
          })
        : ratedRating,
      percentFill: !isHyperRoll
        ? leaguePoints / 100
        : ratedRating / hyperRollRank.threshold?.max,
      wins,
      losses,
      summaryColor: rankColor,
      textTitleColor: winrateColor,
      barColor: winrateColor,
    };
  }).filter(Boolean);

  return (
    <>
      {PROFILE_QUEUES.map((queue) => {
        const isRegularRanked =
          queue.key === QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTft];
        const queueName = queue.name;
        const showGraph = isRegularRanked;
        const rankInfo = leagues.find((rank) => rank.queue_type === queue.key);

        if (!rankInfo && !isRegularRanked) return null;

        const {
          wins = 0,
          losses = 0,
          league_points: leaguePoints = 0,
          tier,
          rank,
          // Hyper Roll
          rated_rating: ratedRating = 0,
        } = rankInfo || {};
        const division = !topRanks.includes(tier) ? rank : null;

        const tierSymbol = RANK_SYMBOL_TO_STR[RankSymbol(tier)];
        const tierName = tierSymbol
          ? [tierSymbol.t.name, tierSymbol.t.fallback]
          : ["common:rank.unranked", "Unranked"];
        const tierString = t([...tierName]);
        const nonRomanDivision = convertRankFromRomanToNumber(division);

        const isHyperRoll =
          queue.key ===
          QUEUE_SYMBOLS_TO_QUEUE_NAMES[QUEUE_SYMBOLS.rankedTftTurbo];
        const hyperRollRank = isHyperRoll
          ? getHyperRollRanksByRating(ratedRating)
          : null;

        return (
          <ProfileRank
            key={queueName[0]}
            title={t(...queueName)}
            image={
              !isHyperRoll
                ? Static.getRankedMedal(tier || "unranked", 80)
                : Static.getHyperRollRankImage(hyperRollRank.key)
            }
            name={
              !isHyperRoll
                ? (tierString &&
                    nonRomanDivision &&
                    `${tierString} ${nonRomanDivision}`) ||
                  tierString
                : t(...hyperRollRank.t)
            }
            points={
              !isHyperRoll
                ? t("lol:leaguePoints", "{{points}} LP", {
                    points: leaguePoints,
                  })
                : ratedRating
            }
            percentFill={
              !isHyperRoll
                ? leaguePoints / 100
                : ratedRating / hyperRollRank.threshold?.max
            }
            color={
              !isHyperRoll
                ? tierSymbol
                  ? getRankColor(tierSymbol.key)
                  : "var(--shade2)"
                : hyperRollRank.color
            }
            wins={wins}
            losses={losses}
            belowTitle={showGraph && t("tft:placements", "Placements")}
            below={
              showGraph ? (
                <>
                  <ProfileOverviewPlacementDistribution account={account} />
                  <TFTStats account={account} />
                </>
              ) : null
            }
          />
        );
      })}
      <ProfileRank.Accordion ranksList={secondaryQueues} />
    </>
  );
}
