export default function getItemByItemSet({ key, value }, itemsBySet = {}) {
  if (
    typeof itemsBySet !== "object" ||
    [key, value].some((i) => typeof i === "undefined")
  ) {
    return null;
  }
  for (const itemKey in itemsBySet) {
    if (itemsBySet[itemKey]?.[key] === value) {
      return itemsBySet[itemKey];
    }
  }
  return null;
}
