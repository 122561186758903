import React from "react";

import StaticTFT from "@/game-tft/static.mjs";
import TraitCounter from "@/game-tft/TraitCounter.jsx";

const MatchTraitList = ({ traits, set, max = 8, size = 20 }) => {
  const sortedTraits = StaticTFT.sortTraits(traits, set).slice(0, max);

  return (
    <>
      {sortedTraits.map((trait, index) => {
        const traitName = trait.name;
        return (
          <TraitCounter
            key={index}
            set={set}
            traitName={traitName}
            iconSize={size}
            count={trait.num_units}
            traitStyle={trait.style}
            depthColors
          />
        );
      })}
    </>
  );
};

export default MatchTraitList;
