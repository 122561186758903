import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { HYPER_ROLL_RANKS } from "@/game-tft/constants.mjs";
import Static from "@/game-tft/static.mjs";
import { CardContainer } from "@/shared/Profile.style.jsx";

const RankIcon = styled("img")`
  width: 22%;
  border-radius: 60%;
  border: var(--sp-1) solid var(--shade6);
  padding: var(--sp-4);
  margin-right: var(--sp-4_5);
  margin-left: var(--sp-3);
`;

const RankInfo = styled("div")`
  width: 40%;
`;

const RankTitle = styled("span")`
  line-height: var(--sp-4_5);
  color: var(--shade1);
  font-size: var(--sp-3);
`;

const Rank = styled("div")`
  color: ${({ rank }) => (rank ? HYPER_ROLL_RANKS[rank]?.color : "inherit")};
  font-size: var(--sp-4_5);
  line-height: var(--sp-4);
  font-weight: 700;
  margin: var(--sp-1) 0;
`;

const HyperRollCard = styled(CardContainer)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--sp-6);
`;

const Rating = styled("div")`
  font-weight: bold;
  font-size: var(--sp-3);
  line-height: var(--sp-5);
  color: var(--shade1);
`;

const HyperRollRankCard = ({ leagueInfo: { rated_tier, rated_rating } }) => {
  const { t } = useTranslation();
  const TitleText = t("tft:hyperrollRank", "Hyper Roll Rank");
  if (!rated_tier || !rated_rating) return null;

  const rank = HYPER_ROLL_RANKS[rated_tier];

  return rank?.key === "none" ? null : (
    <HyperRollCard>
      <RankIcon src={Static.getHyperRollRankImage(rated_tier)} />
      <RankInfo>
        <RankTitle>{TitleText}</RankTitle>
        <Rank rank={rated_tier}>{t(...rank.t)}</Rank>
        <Rating>{rated_rating}</Rating>
      </RankInfo>
    </HyperRollCard>
  );
};

export default HyperRollRankCard;
