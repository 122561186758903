import React, { memo } from "react";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const AugmentsList = styled("div")`
  display: flex;
  align-items: center;
`;

const MatchAugmentList = ({ augments, set, size = 26 }) => {
  const state = useSnapshot(readState);
  const [hasError, setHasError] = React.useState(false);
  if (!augments?.length || !state.tft[set]?.augments) return "";

  return (
    <>
      <AugmentsList>
        {augments.map((augment) => {
          return (
            <AugmentTooltip augment={augment} key={augment} set={set}>
              <img
                src={StaticTFT.getAugmentImage(augment, set)}
                alt={augment}
                width={size}
                height={size}
                onError={(e) => {
                  setHasError(true);
                  e.target.style.display = "none";
                }}
              />
            </AugmentTooltip>
          );
        })}
      </AugmentsList>
      {!hasError ? <div className="gap-dot" /> : ""}
    </>
  );
};

export default memo(MatchAugmentList);
